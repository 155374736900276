export const labelTypes = [
  { id: 'one_label_on_a4_lt_pdf', name: 'Plik PDF, jedna etykieta na A4, lewy, górny narożnik' },
  { id: 'one_label_on_a4_rt_pdf', name: 'Plik PDF, jedna etykieta na A4, prawy, górny narożnik' },
  { id: 'one_label_on_a4_lb_pdf', name: 'Plik PDF, jedna etykieta na A4, lewy, dolny narożnik' },
  { id: 'one_label_on_a4_rb_pdf', name: 'Plik PDF, jedna etykieta na A4, prawy, dolny narożnik' },
  { id: 'one_label_on_a4_pdf', name: 'Plik PDF, jedna etykieta na A4' },
  {
    id: 'four_labels_on_a4_pdf',
    name: 'Plik PDF, cztery etykiety na A4, pierwsza etykieta drukowana od lewej',
  },
  {
    id: 'four_labels_on_a4_right_pdf',
    name: 'Plik PDF, cztery etykiety na A4, pierwsza etykieta drukowana od prawej',
  },
  { id: 'roll_160x100_pdf', name: 'Plik PDF, jedna etykieta na kartce o wymiarach 160mm x 100mm' },
  { id: 'roll_160x100_zebra', name: 'Plik w języku ZPL (na drukarki termiczne)' },
  { id: 'roll_160x100_zebra_epl', name: 'Plik w języku EPL (na drukarki termiczne)' },
];
